<template>
  <div class="PullRefresh">
    <van-pull-refresh
      v-model="refreshing"
      success-text="刷新成功"
      @refresh="onRefresh"
      :disabled="disabled"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad"
        :offset="10"
      >
        <slot></slot>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
export default {
  props: {
    disabled:{
      type:Boolean,
      default:false,//是否禁止下拉刷新 默认为false
    }
  },
  data() {
    return {
      refreshing:false,
      loading:false,
      finished:false,
    };
  },
  methods: {
    onRefresh(){
      this.$emit('onRefresh');
    },
    onLoad() {
      this.$emit('onLoad');
    },
  },
};
</script>
