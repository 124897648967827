<template>
  <div>
    <div class="marquee flex">
      <van-notice-bar
        v-if="marquee"
        class="mar"
        color="red"
        background="#fff"
        :left-icon="laba"
        :text="marquee"
        speed="40"
      />
    </div>
    <!-- <AdvSwiper :advList="homeAd" class="adv" /> -->
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <v-touch
      @swipeleft="swipeLeft"
      :swipe-options="{ direction: 'horizontal' }"
    >
      <div class="introduce">
        <div class="nav flex">
          <div
            class="nav-item"
            v-for="item in icons"
            :key="item.id"
            @click="jump(item)"
          >
            <ImgDecypt class="nav-header" :src="item.icon" :round="true" />
            <p class="nav-title">{{ item.text }}</p>
          </div>
        </div>
        <div class="video-list">
          <div class="video-title">推荐视频</div>
          <pullrefresh
            :disabled="true"
            @onLoad="onLoad"
            ref="pull"
            v-show="videoList.length > 0"
          >
            <div class="v-list">
              <div
                class="v-item"
                v-for="(item, index) in videoList"
                :key="item.id || item.adv.id + '' + index"
              >
                <div
                  class="video-item"
                  v-show="item.bType == 1"
                  @click="goFullPlay(item)"
                >
                  <ImgDecypt class="v-img" :src="item.videoCover" />
                  <!-- <div class="tip" v-show="!isVip">
                    <p>非vip可试看{{item.prevtime}}秒</p>
                    <p>充值<span class="red-vip">VIP</span>无限看</p>
                  </div> -->

                  <div class="vipLabel" v-if="item.isVip">VIP</div>
                  <div class="jb" v-else-if="item.price"><span></span></div>
                  <div class="tip" v-else>
                    <p>非vip可试看{{ item.prevtime }}秒</p>
                    <p>充值<span class="red-vip">VIP</span>无限看</p>
                  </div>
                  <div class="sec">
                    <div class="sec-title ellipsis">{{ item.content }}</div>
                    <div class="sec-bottom ellipsis">
                      <span class="video-time"
                        >完整版{{ item.playTime | videotime }}</span
                      >
                      <span
                        class="video-tag"
                        v-for="(items, index) in item.flags"
                        :key="index"
                        >#{{ items }}</span
                      >
                    </div>
                  </div>
                </div>
                <div v-if="item.bType == 4" @click="jumpAdd(item.adv)">
                  <ImgDecypt
                    class="video-ad"
                    :src="item.adv && item.adv.pictureUrl"
                  />
                </div>
              </div>
            </div>
          </pullrefresh>
          <van-loading
            type="spinner"
            color="#f21313"
            v-show="videoList && videoList.length == 0 && !noData"
          />
          <nodata v-show="noData" />
        </div>
      </div>
    </v-touch>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import pullrefresh from "@/components/PullRefresh/index.vue";
import { iconList } from "@/api/app.js";
import { buried } from "@/api/index.js";
import { jumpAdv } from "@/utils/getAdv.js";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { jumpVideo } from "@/utils/index.js";
export default {
  name: "introduce",
  components: {
    AdvSwiper,
    pullrefresh,
  },
  data() {
    return {
      laba: require("@/assets/png/laba.png"),
      getterAdsGood: [],
      isVip:
        JSON.parse(sessionStorage.getItem("userInfo")).vipExpire * 1000 >
        new Date().getTime()
          ? true
          : false, //判断是否为会员
      pageNum: 1, //页码
      icons: [], //首页icons列表
      videoList: [], //视频列表
      noData: false, //暂无数据
      marquee: "",
      adsGood: JSON.parse(sessionStorage.getItem("appConfig")).pageCfg.filter(
        (item) => {
          return item.id == "spec_coll";
        }
      ), //好片大赏
      gridAdvList: [],
    };
  },
  created() {
    this.getIcon();
    this.homeAd = getAdItem(AdType.homeRecommend);
    this.gridAdvList = getAdItem(AdType.homeAdv).slice(0, 10);
    this.getterAdsGood = this.adsGood
      ? this.adsGood[0].subTabs.splice(0, 1)
      : [];
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    jump(item) {
      if (item.text === "色区") {
        this.action(2);
      } else if (item.text === "好片大赏") {
        this.action(3);
      }
      if (
        item.jumpTo.indexOf("good_video") > -1 &&
        this.getterAdsGood.length > 0 &&
        item.jumpTo.indexOf("?id=") == -1
      ) {
        item.jumpTo = item.jumpTo + `?id=${this.getterAdsGood[0].id}`;
      }
      jumpAdv(item, "user");
    },
    goFullPlay(item) {
      //点击视频列表去全屏播放页面
      jumpVideo(item);
    },
    //拿取模块icon的list
    async getIcon() {
      let req = {
        isVip: true,
        pageNum: this.pageNum,
        pageSize: 20,
        type: "watch",
      };
      let ret = await this.$Api(iconList, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        console.log(ret.data);
        this.icons = ret.data.jinGangQv
          ? ret.data.jinGangQv.filter((e) => {
              return (
                e.text != "福利签到" &&
                e.text != "全民代理" &&
                e.text != "泡芙导航" &&
                e.text != "裸聊" &&
                e.text != "AV解说" &&
                e.text != "优质APP"
              );
            })
          : [];
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        this.videoList = this.videoList.concat(list);
        this.marquee = ret.data.marquee;
        if (list.length < 20) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    onLoad() {
      //上拉加载更多
      this.pageNum++;
      this.getIcon();
    },
    swipeLeft() {
      this.$router.push("/home/tag");
    },
    // 统计色区的点击量
    async action(location) {
      let req = {
        location: location,
        type: 1,
      };
      await this.$Api(buried, req);
    },
  },
};
</script>
<style lang="scss" scoped>
// .noticeBar {
//   height: 24px;
//   font-size: 12px;
//   margin: 7px 0 12px;

//   /deep/ .van-notice-bar__left-icon {
//     color: $vermillion;
//   }
// }
.marquee {
  height: 21px;
  align-items: center;
}
.laba {
  margin: 0 7px 0 2px;
  width: 15px;
  height: 15px;
  background: url("../../assets/png/laba.png") center center no-repeat;
  background-size: 100%;
}
.mar {
  flex: 1;
  font-size: 12px;
  height: 21px;
  /deep/ .van-notice-bar__left-icon {
    color: $vermillion;
  }
}
.lunbo {
  height: 150px;
  position: relative;
}
.swiper-all {
  width: 100%;
  height: 100%;
}
.ad-img {
  width: 100%;
  height: 100%;
}
.swiper-pagination {
  right: 16px;
  bottom: 10px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet {
  margin-left: 6px;
  width: 6px;
  height: 6px;
}
.swiper-pagination /deep/ .swiper-pagination-bullet-active {
  width: 24px;
  background: #ffffff;
  border-radius: 4px;
}
.nav {
  // flex-wrap: wrap;
  padding: 0 10px;
  justify-content: space-between;
  display: flex;
  overflow-x: auto;
  white-space: nowrap; // 不换行
}
.nav-item {
  margin-top: 8px;
  align-items: center;
  margin-right: 24px;
}
.nav-item:last-child {
  margin-right: 0;
}
.nav-header {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.nav-title {
  margin-top: 2px;
  text-align: center;
}
.introduce {
  padding: 2px 10px;
}
.video-list {
  margin-top: 20px;
}
.video-title {
  font-size: 15px;
}
.v-item {
  margin-top: 18px;
  background: #f1f0f0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.v-item:nth-of-type(1) {
  margin-top: 10px;
}
.tip {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 5px 10px;
  border-radius: 38px;
  text-align: center;
  color: #ffffff;
  background: rgba($color: #000000, $alpha: 0.3);
}
.tip > p:nth-child(2) {
  margin-top: 4px;
}
.jb {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 22px;
  text-align: center;
  line-height: 32px;
  border-bottom-left-radius: 6px;
  background: $vermillion;
}
.jb > span {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("../../assets/png/money_icon.png") center center no-repeat;
  background-size: 100%;
}
.vipLabel {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 22px;
  // text-align: center;
  // line-height: 22px;
  border-bottom-left-radius: 6px;
  background: $vermillion;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
}
.v-img {
  width: 100%;
  height: 174px;
}
.sec {
  padding: 0 9px;
}
.sec-title {
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #cccccc;
}
.sec-bottom {
  height: 40px;
  line-height: 40px;
}
.video-time {
  display: inline-block;
  padding: 4px;
  color: #ffffff;
  background: $vermillion;
  line-height: normal;
  border-radius: 4px;
}
.video-tag {
  margin-left: 15px;
  color: #8d8787;
}
.red-vip {
  color: $vermillion;
}
.video-ad {
  width: 100%;
  height: 110px;
  border-radius: 6px;
}
.adv /deep/ .swiperSlide {
  height: 156.25px !important;
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
